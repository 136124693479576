<template>
  <div></div>
</template>

<script>
import config from '../../config'

export default {
  name: 'Login',
  data: () => ({
    valid: false,
    showPassword: false,
    successMessage: null,
    sessionTimeout: false,
    autofill: false,
    intervalDetectAutofill: null
  }),
  created () {
    document.title = config.title
  },
  mounted () {
    const reauth = this.$route.query.reauth

    if (reauth) {
      this.checkLogin()
    }
  },
  methods: {
    checkLogin () {
      location.href = config.autUrl +
        '?acr_values=' + config.acrValues +
        '&grant_type=' + config.grantType +
        '&client_id=' + config.clientId +
        '&redirect_uri=' + config.redirectUri +
        '&response_type=' + config.responseType +
        '&nonce=' + config.nonce +
        '&scope=' + config.scope
    }
  }
}
</script>
